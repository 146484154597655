/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable eqeqeq */
import React, { useState } from "react";
import copy from 'copy-to-clipboard';
import "./TranslationInput.css";
import api from "../api";

export default function TranslationInput(props) {
  const [isStatusError, setIsStatusError] = useState(false);
  const [statusText, setStatusText] = useState('');
  const [language, setLanguage] = useState('English');
  const [transliteration, setTransliteration] = useState('');
  const [translations, setTranslations] = useState([]);
  const [translating, setTranslating] = useState(false);
  const [converting, setConverting] = useState(false);
  const [text, setText] = useState('');
  const [imageBase64, setImageBase64] = useState(null);

  const azureLanguageMap = {
    'fr': 'fr-FR',
    'zh-CN': 'zh-CN',
  }

  const handleCopy = async text => {
    console.log(text);
    copy(text);
  }


  const handleDownload = async ev => {
    ev.preventDefault();
    setConverting(true);
    setIsStatusError(false)
    setStatusText('')
    api
      .convertTextToSpeech({ text, language: azureLanguageMap[language] })
      .then(result => {
        setImageBase64(result.audio)
      })
      .catch(err => {
        setIsStatusError(true)
        setStatusText(`Speech download error: ${err.message}`);
      })
      .finally(result => {
        setTranslating(false)
        setConverting(false);
      });
  }

  const handleInputChange = async ev => {
    const inputText = ev.target.value;
    setText(inputText)
    
    // setAddressInfo({
    //   ...addressInfo,
    //   postalcode: ev.target.value
    // })

    if (!translating) {
      setTimeout(() => {
        setTranslating(true)
        setIsStatusError(false)
        setImageBase64(null)
        setStatusText('Translating...')
        api
          .translate({ text: inputText, targetLanguage: 'en' })
          .then(result => {
            console.log('result', result);
            setTranslations(result.translations)
            setTransliteration(result.transliteration)
            setLanguage(result.detections[0].language)
            setStatusText('Translated from ' + result.detections[0].language + ' (' + result.detections[0].confidence + ')') 
          })
          .catch(err => {
            setIsStatusError(true)
            setStatusText(`Translation error: ${err.message}`);
          })
          .finally(result => {
            setTranslating(false)
          });
      }, 1000);
    }
  }

  const rednerMain = () => {
    return (
      <div className="container translation">
        <div className="row align-items-center">

          <div className="col-xl-9 col-lg-8 col-md-7 col-sm-12">
            <form>
              <input className="main-text-input" placeholder="Enter your text" onChange={handleInputChange} onfocus="blur();" value={text} type="text"/>
                <div className="row action-buttons-container">
                  <div className="col-xl-3 col-md-6 col-6">
                    <button className="action-button" disabled={converting} onClick={handleDownload}>{converting ? "Converting" : "Convert"}</button>
                  </div>
                  <div className="col-xl-3 col-md-6 col-6">
                    <button className="action-button" disabled={!text || text === ""} onClick={handleCopy.bind(this, text)}>Copy</button>
                  </div>
                  <div className="col-xl-6 col-md-12 col-sm-12">
                    {imageBase64 
                      ? <a className='action-button' href={'data:audio/wav;base64,' + imageBase64 } download={text + '.wav'}>Download</a>
                      : <a className='action-button disabled' href="#">Download</a>
                    }
                  </div>
                </div>
            </form>

            <div className="info-container">
              <p className={isStatusError ? "error-text" : "normal-status-text"}>
                {statusText}
              </p>
            </div>

            <div className="translation-container">
              <ul>
                { translations.map((value, index) => {
                  return (
                    <li key={index}>
                      <span>{value}</span>
                      <span onClick={handleCopy.bind(this, value)} className="clipboard-icon" role="img" aria-label="copy">📋</span>
                    </li>
                  )
                }) }
              </ul>
              { transliteration && 
              <div className="transliteration">
                <p>{transliteration} <span onClick={handleCopy.bind(this, transliteration)} className="clipboard-icon" role="img" aria-label="copy">📋</span></p>
              </div>
              }
            </div>
          </div>

        </div>
      </div>
    );
  };

  return (
    rednerMain()
  );
}

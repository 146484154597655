import React from 'react';
import './App.css';

import TranslationInput from "./components/TranslationInput";

function App() {
  return (
    <div className="App">
      <TranslationInput/>
    </div>
  );
}

export default App;

let API_BASEPATH = `${process.env.REACT_APP_TRANSLATION_API_BASEPATH}`;
if (process && process.env && process.env.NODE_ENV === 'development') {
  API_BASEPATH = 'http://localhost:3000/prod';
}

const translate = (body) => {
  console.log('translate is getting called with ', body);
  
  return window
    .fetch(`${API_BASEPATH}/translate`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(body)
    })
    .then(res => {
      return res.json();
    })
    .then(data => {
      if (!data || data.error || !data.translations) {
        throw new Error(data.message);
      } else {
        return data;
      }
    });
};

const convertTextToSpeech = (body) => {
  return window
    .fetch(`${API_BASEPATH}/synthesize`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(body)
    })
    .then(res => {
      return res.json();
    })
    .then(data => {
      if (!data || data.error) {
        throw Error(data.message);
      } else {
        return data;
      }
    });
};

const api = {
  convertTextToSpeech,
  translate,
};

export default api;
